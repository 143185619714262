<template>
  <LayoutPillComponent
    :class="$style.root"
    :localizedData="data"
    :type="linkType"
    componentType="linkWithFallback"
    name="clusters-slug"
    :sizeOption="sizeOption"
    :style="{
      '--color--background': data?.background_color,
    }"
  >
    {{ title }}
  </LayoutPillComponent>
</template>

<script setup>
const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  },
  sizeOption: {
    type: String,
    default: 'narrow',
    validator: (value) => ['default', 'narrow', 'tiny'].includes(value),
  },
})

const title = computed(() => i18nGetLocalizedTitle(props?.data))
</script>

<style module>
.root {
  composes: reset-link button from global;
  --pill--background-color: var(--color--background, var(--color--white));
  --pill--border-color: var(--color--background, var(--color--white));
  --pill--border-color--hover: var(--color--gray);
}
</style>
